import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title={data.strapi.page.title}></SEO>
    <Hero options={data.strapi.page.hero}></Hero>
  </Layout>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query PageNotFoundQuery {
    strapi {
      page(id: "5eec5094fb0d2543a05b34c9") {
        title
        hero {
          id
          image {
            url
          }
          heading
          rich_text_heading
          id
          cta {
            id
            link
            internal_link
            position
            text
          }
        }
      }
    }
  }
`;
